/* You can add global styles to this file, and also import other style files */
:root {
  --main-background: #fff;
  --off-background: #f2f2f2;
  --font-color: #333333;
  --gray: #828282;
  --top-box-color: #fff;
  --divider-color: #0000001f;
  --border-bottom-color: #bdbdbd;
  --dark-gray: #4f4f4f;
  --secondary-font-color: #0052a8;
  --placeholder-font-color: #bdbdbd;
  --underline-color: #0000006b;
  --focused-underline-color: #2c98f0;
  --active-tab-color: #0052a8;
  --unactive-tab-color: #828282;
  --check-box-border-color: #bdbdbd;
  --check-box-background-color: #0052a8;
  --dropdown-button-color: #bdbdbd;
  --dark-icon-color: #333333;
  --light-icon-color: #828282;
  --footer-tab-top-border: #0000001f;
  --footer-tab-background: #fff;
  --off-white: #0000006b;
  --link-color: #800080;
  --coret-color: #0000006b;
  --off-white: #747474;
  --bullet-point: #000;
  --main-blue-color: #0052a8;
  --label-color: #747474;
  --expansion-panel-text-1: #747474;
  --expansion-panel-text-2: #4f4f4f;
  --expansion-panel: #0000004d;
  --box-shadow: #00000040;
  --popup-color: #fafafa;
  --font-color-dark: #000;
  --blue-box: #0052a8;
  --tab-border: #0000001f;
  --input-field-bg: #0000000a;
  --hr-color: #bdbdbd;
  --tnc-text-color: rgba(0, 0, 0, 0.42);
  --tab-text-color: #828282;
  --border-bottom-color: rgba(0, 0, 0, 0.12);
  --mute-btn-color: #f0a8a8;
  --button-border: #0052a8;
  --button-color: #0052a8;
  --button-bg: #fff;
  --alert: #828282;
  --tab-bg: #FFF;
}

html,
body {
  height: 100%;
}

body {
  color: var(--font-color);
  background: var(--main-background);
  margin: 0;
  font-family: "Montserrat-Medium", Roboto, "Helvetica Neue", sans-serif;
}

label,
div,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat-Medium";
  color: var(--font-color);
}

th,
td {
  padding: 10px 5px;
}

.dark-primary {
  --main-background: #121212;
  --off-background: rgba(75, 178, 249, 0.15);
  --font-color: #f1f1f1;
  --top-box-color: #3c3c3c;
  --gray: #fff;
  --dark-gray: #fff;
  --divider-color: #3c3c3c;
  --border-bottom-color: #3c3c3c;
  --secondary-font-color: #fff;
  --underline-color: #f2f2f2;
  --focused-underline-color: #2c98f0;
  --active-tab-color: #0052a8;
  --unactive-tab-color: #828282;
  --check-box-border-color: #bdbdbd;
  --check-box-background-color: #0052a8;
  --dropdown-button-color: #f2f2f2;
  --dark-icon-color: #fff;
  --light-icon-color: #dddddd;
  --footer-tab-top-border: #3c3c3c;
  --footer-tab-background: #121212;
  --off-white: #f2f2f2;
  --link-color: #0052a8;
  --coret-color: #f2f2f2;
  --off-white: #f2f2f2;
  --bullet-point: #fff;
  --main-blue-color: #0052a8;
  --label-color: #f2f2f2;
  --expansion-panel-text-1: #d1d1d1;
  --expansion-panel-text-2: #fff;
  --expansion-panel: #f2f2f2;
  --box-shadow: rgba(255, 255, 255, 0.89);
  --popup-color: #000000;
  --font-color-dark: #fff;
  --blue-box: #121212;
  --tab-border: #f2f2f231;
  --input-field-bg: #bdbdbd17;
  --hr-color: #f2f2f2;
  --tnc-text-color: #f2f2f2;
  --tab-text-color: #f2f2f2;
  --border-bottom-color: rgba(255, 255, 255, 0.39);
  --mute-btn-color: #f0a8a8;
  --button-border: #0052a8;
  --button-color: #0052a8;
  --button-bg: #121212;
  --alert: #828282;
  --tab-bg: #000;
  background: var(--main-background);
  label,
  div,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Montserrat-Medium";
    color: var(--font-color);
  }
  th,
  td {
    padding: 10px 5px;
    color: var(--font-color);
  }

  .mat-radio-outer-circle {
    border-color: var(--font-color);
  }

  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
    .mat-expansion-panel-header:hover {
    background: var(--main-background);
  }
}

mat-bottom-sheet-container {
  background: var(--main-background) !important;
}

app-root > div{
  max-width: 500px;
    margin: auto;
    background: #fff;
    height: calc(100vh - 62px);
}
body{
  // background-image: url('./assets/images/istockphoto-913219882-612x612.jpg');
  background:linear-gradient(0deg, rgba(242, 242, 242, 0.9), rgba(242, 242, 242, 0.8)), url(./assets/images/istockphoto-913219882-612x612.jpg);
      background-size:100%;
      background-repeat: no-repeat;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("assets/fonts/montserrat/Montserrat-Medium.eot");
  src: url("assets/fonts/montserrat/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium")
      format("svg"),
    url("assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype"),
    url("assets/fonts/montserrat/Montserrat-Medium.woff") format("woff"),
    url("assets/fonts/montserrat/Montserrat-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.mat-expansion-panel {
  background: var(--main-background);
}

.width-100 {
  width: 100%;
  float: left;
}

.margin-left-10 {
  margin-left: 10px;
}

.padding-15 {
  padding: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.dark-gray {
  color: var(--dark-gray) !important;
}

.red {
  color: #df440d !important;
}

.green {
  color: #219653 !important;
}

.blue {
  color: #2c98f0 !important;
}

.gray {
  color: var(--gray) !important;
}

.bg-red {
  background: #df440d;
}

.bg-green {
  background: #219653;
}

.bg-blue {
  background: #2c98f0;
}

.bg-gray {
  background: #828282;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

app-tabs {
  .mat-tab-group-inverted-header .mat-tab-header {
    border-top: 1px solid var(--footer-tab-top-border);
    border-bottom: none;
  }

  .mat-tab-label {
    img.active {
      display: none;
    }
    img.in-active {
      display: block;
    }
    div {
      color: var(--unactive-tab-color);
    }
  }

  .mat-tab-label-active {
    img.active {
      display: block;
    }
    img.in-active {
      display: none;
    }
    div {
      color: var(--active-tab-color);
    }
  }
  mat-tab-group {
    width: 100%;
    max-width: 500px;
    margin:auto;
    position: fixed;
    bottom: 0;
    // background: var(--footer-tab-background);
    background: #f2f2f2;
    height: 62px;

    mat-tab{
      background: #fff;
    }
  }
}

// tab css
mat-tab-group {
  width: 100vw;
  position: fixed;
  bottom: 0;
  background: var(--footer-tab-background);
}

.mat-tab-label {
  min-width: 25% !important;
  height: 62px !important;
  opacity: 1 !important;
  padding: 0 !important;
}

.mat-tab-label-content {
  display: inline-grid !important;
  img {
    width: 29px;
    margin: auto;
  }
  font-size: 13px;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #0052a8;
  height: 4px;
  position: absolute;
  bottom: 2px;
  top: unset;
}

.button-group {
  width: 100%;
  display: flex;
  button {
    width: calc(48% - 16px);
    margin: 0 1%;
  }
}

.no-data-found {
  height: calc(100vh - 300px);
  text-align: center;
  display: inline-flex;
  width: 100%;
  .content {
    margin: auto;
    text-align: center;
    width: 100%;
    color: #4f4f4f;
    line-height: 10px;
    font-size: 15px;
    img {
      margin-bottom: 20px;
    }
  }
}

.list-swipe {
  margin: 0;
  box-shadow: none;
  padding: 0;
}

.list-swipe {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  .mark {
    display: none;
  }
}

.left-swipe {
  transform: translate(-80px, 0) !important;
}

.options-btn {
  transform: translate(calc(100vw - 105px), 0) !important;
  width: 80px !important;
}

.options-btn.active {
  padding: 0 !important;
  .option-list:first-child {
    display: none;
  }
  .option-list {
    background: red;
    height: 100px;
    display: inline-flex;
    color: #fff;
    p {
      color: #fff;
    }
  }
}

.gray-button {
  background: #bdbdbd;
  color: #333;
  font-family: "Montserrat-Medium";
  border-radius: 0 !important;
}

.blue-button {
  background: #2c98f0;
  color: #fff;
  font-family: "Montserrat-Medium";
  border-radius: 0 !important;
}

.red-button {
  background: #df440d;
  color: #fff;
  font-family: "Montserrat-Medium";
  border-radius: 0 !important;
}

app-portfolio {
  .report {
    mat-grid-tile {
      width: calc((50% - 0px) * 1 + 0px) !important;
    }
    .border-top {
      border-top: 1px solid #bdbdbd;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

app-home,
bottom-sheet-detail,
app-order,
app-portfolio,
app-funds,
app-add-funds,
app-withdraw-funds,
app-account,
app-online-ipo,
app-bodhitree,
app-margin-against-holdings,
app-subscribed,
app-invite-friends,
app-market-section {
  // .mat-tab-group.mat-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  //     background-color: #0052A8;
  //     height: 4px;
  //     position: absolute;
  //     bottom: 2px;
  //     top: unset;
  // }
  // grid css
  .mat-grid-list {
    width: 100%;
    // height: 100px !important;
  }
  .align-left {
    text-align: left;
    .mat-figure {
      justify-content: left !important;
      display: block;
    }
  }
  .align-right {
    text-align: right;
    .mat-figure {
      justify-content: flex-end !important;
      display: block;
    }
  }
  .text-center {
    text-align: center;
    .mat-figure {
      justify-content: flex-end !important;
      display: block;
    }
  }
  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 !important;
  }
  .mat-list-base .mat-list-item.mat-2-line {
    height: 100px;
  }
  .mat-tab-label-container {
    background: #fff;
  }
}
.mat-tab-list{
  background: var(--tab-bg);
}
.c-content{
  background: var(--main-background);
}
bottom-sheet-detail {
  .c-content {

    padding-top: 16px;
    .title,
    .price {
      font-size: 18px;
      margin: 10px 0;
    }
  }
  .mat-list-base .mat-list-item.mat-2-line {
    height: 70px !important;
  }
  .content {
    .mat-grid-tile .mat-figure {
      text-align: center;
      display: block !important;
      .mat-line.label {
        width: 100%;
        opacity: 0.6;
      }
    }
    mat-grid-list.mat-grid-list {
      border-bottom: 1px solid var(--divider-color);
      margin-top: 16px;
    }
  }
  tr:nth-child(even) td {
    color: var(--font-color);
  }
  th {
    color: var(--font-color);
  }
  tr:nth-child(odd) {
    color: var(--font-color);
  }
  table {
    background: transparent;
    width: 100%;
  }
  .head {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0;
    position: relative;
    .material-icons {
      margin-top: 10px;
      color: var(--gray);
    }
    .material-icons.back {
      color: var(--font-color);
    }
    .cross {
      position: absolute;
      border-top: 2px solid #727272;
      width: 27px;
      transform: rotate(45deg);
      transform-origin: 0% 0%;
      right: 5px;
      top: 21px;
    }
    h2 {
      width: 100%;
      margin-left: 20px;
      padding-top: 5px;
      color: var(--font-color);
    }
  }
  .table-container {
    border: 1px solid var(--off-background); //#F2F2F2;
    border-radius: 8px;
  }
  .info {
    padding: 30px 0;
    .mat-grid-list {
      border-bottom: 1px solid var(--font-color);
    }
    .text-left {
      .mat-figure {
        justify-content: left;
        opacity: 0.7;
      }
    }
    .text-left.dark-color {
      .mat-figure {
        opacity: 1;
      }
    }
  }
  .nodge {
    width: 55px;
    height: 5px;
    border-radius: 20px;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--dropdown-button-color);
  }
 
  .filter-container {
    padding-top: 30px;
    .filters {
      width: 100%;
      float: left;
      padding: 0 0 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid #828282;
      .filter-option {
        width: fit-content;
        float: left;
        padding: 5px 15px;
        border: 1px solid #828282;
        border-radius: 30px;
        margin: 10px 20px 10px 10px;
        color: #828282;
      }
      .active {
        background: #2c98f0;
        color: #fff;
        border-color: #2c98f0;
      }
    }
    .btn-grp {
      margin-top: -20px;
      margin-bottom: 20px;
      text-align: right;
      float: left;
      width: 100%;
      button {
        margin-left: 10px;
      }
    }
  }
  h1 {
    color: #333333;
  }
  .border-none {
    border: none !important;
  }
}
app-convertbottom{
  .nodge {
    width: 55px;
    height: 5px;
    border-radius: 20px;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--dropdown-button-color);
  }
  .mat-divider {
    border-top-color: var(--divider-color);
}
}
app-buy-sell {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
}

.transparent-btn {
  background: transparent;
  border: unset !important;
  box-shadow: unset !important;
  color: #626262;
}

.mat-sheet-height {
  max-height: 95vh !important;
}

.mat-sheet-height-100 {
  max-height: 100vh !important;
  height: 100vh;
}

// auto complete serach
.mat-checkbox-inner-container {
  position: absolute !important;
  right: 10px;
  top: 17px;
}

.mat-option {
  border-bottom: 1px solid var(--gray);
}

.cdk-overlay-pane {
  left: 0 !important;
  // width: 100vw !important;
  // padding: 10px;
  .mat-autocomplete-panel {
    padding: 10px !important;
    margin-top: 20px !important;
    min-height: calc(100vh - 100px) !important;
    background-color: var(--main-background);
  }
  .mat-checkbox-frame {
    border-color: var(--check-box-border-color);
  }
}

.mute {
  color: var(--mute-btn-color);
}

// radio
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2c98f0 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #2c98f0 !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2c98f0 !important;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2c98f0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: transparent;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2c98f0;
}

list-item .mat-line:nth-child(n + 2) {
  font-size: 12px !important;
}

.mat-list-base .mat-list-item {
  font-size: 14px;
}
app-home {
  .mat-divider {
    border-top-color: var(--divider-color);
  }
}
app-order {
  .mat-divider {
    border-top-color: var(--divider-color);
  }
}

app-portfolio {
  .mat-divider {
    border-top-color: var(--divider-color);
  }
}
app-funds {
  .mat-divider {
    border-top-color: var(--divider-color);
  }
}
app-account {
  .mat-divider {
    border-top-color: var(--divider-color);
  }
}

app-order,
app-portfolio {
  .footer-o {
    font-size: 14px;
    margin-top: 20px;
    position: absolute;
    left: 0;
    background: var(--off-background); //#f2f2f2;
    .mat-figure {
      padding: 15px;
    }
  }
}

app-welcome {
  .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 1.5px solid #ccc !important;
  }

  .mat-checkbox-inner-container {
    right: unset;
    top: unset;
    position: relative !important;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--check-box-background-color);
  }
  .mat-checkbox-frame {
    border-color: var(--check-box-border-color);
  }
}
app-login {
  mat-label {
    color: var(--font-color);
  }
  input::placeholder {
    color: var(--placeholder-font-color) !important;
  }
}
.mat-radio-label {
  white-space: normal !important;
}

.link {
  color: var(--link-color);
  text-decoration: underline;
}

.mat-select-panel {
  .mat-option {
    border-bottom: unset !important;
    color: #747474 !important;
  }
  .mat-option.mat-active {
    background: #80c1f6 !important;
    color: #000 !important;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 10px 0 0 !important;
}

dialog-detail {
  .plan-info .mat-list-item-content:before {
    content: "\2B24" !important;
    color: #df440d !important;
    padding-right: 6px !important;
  }
  .mat-list-item-content {
    padding: 0 !important;
    font-family: Montserrat-m;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.02em;
    color: var(--font-color);
  }
  .miss-out .mat-list-item-content:before {
    content: "⬤" !important;
    color: #f8ce46;
    padding-right: 6px !important;
  }
}

app-upload-documents {
  .instruction .mat-list-item-content {
    font-family: "Montserrat-Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.02em;
    color: var(--off-white);
    border: none;
  }
  // .instruction .mat-list-item-content:before {
  //   content: "⬤" !important;
  //   color: #000;
  //   padding-right: 6px !important;
  // }
  .mat-form-field-infix {
    width: 253px;
  }
  .mat-select-placeholder {
    color: var(--font-color);
  }
}

app-plan-details {
  .mat-checkbox-inner-container {
    position: absolute !important;
    left: 0 !important;
    top: 22px !important;
    right: unset !important;
  }
  .mat-checkbox-label {
    margin-left: 20px;
    white-space: normal;
  }
  .mat-expansion-panel-header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mat-expansion-panel-body {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    /* or 127% */
    letter-spacing: -0.02em;
    /* Gray 2 */
    color: var(--expansion-panel-text-2) !important;
    // padding-left: 15px;
  }
  .mat-expansion-indicator::after {
    color: var(--font-color);
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--check-box-background-color);
  }
  .mat-checkbox-frame {
    border-color: var(--check-box-border-color);
  }
  .mat-card {
    background: var(--main-background);
  }
  mat-expansion-panel {
    border-bottom: 1px solid var(--expansion-panel);
  }
  .mat-card:not([class*="mat-elevation-z"]) {
    box-shadow: 1px 1px 2px 0px var(--box-shadow);
  }
}

app-account {
  .profile {
    .mat-grid-tile:last-child .mat-figure {
      left: unset;
    }
    .mat-grid-tile:first-child .mat-figure {
      right: unset;
    }
  }
  .my-profile {
    // .mat-grid-tile:last-child .mat-figure{
    // 	left: unset;
    // }
    // .mat-grid-tile:first-child .mat-figure{
    // 	right: unset;
    // }
    .mat-expansion-panel-body {
      // padding-left: 30px;
      // padding-right: 30px;
      background-color: var(--off-background); //#F2F2F2;
    }
    .mat-expansion-panel {
      border-radius: 0px;
      box-shadow: unset;
    }
    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
      color: var(--font-color);
    }
    .email {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    .main-box {
      margin-left: 10px;
      margin-right: 10px;
    }
    .email-text {
      font-size: 12px;
    }
  }
}

app-funds {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: unset;
  }
  .mat-expansion-panel-body {
    padding: 10px 24px 10px 24px;
    margin-top: -25px;
  }
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: var(--font-color);
  }
}

app-add-funds {
  .mat-tab-body-content {
    padding: 30px 16px 0px 16px;
  }
  mat-label {
    color: #333333;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  .mat-tab-body-content {
    height: 500px;
    overflow: hidden;
  }
  div.mat-tab-label-active .mat-tab-label-content {
    color: #2c98f0 !important;
  }
  mat-tab-group {
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: var(--main-background);
  }
  .mat-tab-label-container {
    background: var(--main-background);
  }
  .mat-tab-header {
    border-bottom: 1px solid var(--tab-border);
  }
}

app-withdraw-funds {
  .mat-tab-body-content {
    padding: 30px 16px 0px 16px;
  }
  mat-label {
    color: #333333;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  .mat-tab-body-content {
    height: 500px;
    overflow: hidden;
  }
}

app-online-ipo {
  .mat-tab-body-content {
    padding: 30px 16px 0px 16px;
  }
  mat-label {
    color: #333333;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  div.mat-tab-label-active .mat-tab-label-content {
    color: #2c98f0 !important;
    font-size: 14px;
  }
  .mat-tab-labels {
    background-color: var(--off-background); //#F2F2F2;
    justify-content: space-around !important;
    div {
      width: 50%;
      max-height: 42px;
    }
  }
  .mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    color: var(--tab-text-color);
  }
  .mat-tab-body-content {
    height: 100%;
    overflow: visible;
  }
  mat-tab-group {
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: var(--main-background);
  }

  .mat-tab-label-container {
    background: var(--main-background);
  }
  .mat-tab-header {
    border-bottom: 1px solid var(--tab-border);
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--font-color);
  }
}

app-margin-against-holdings {
  .mat-tab-body-content {
    padding: 30px 16px 0px 16px;
  }
  .mat-checkbox-frame {
    border-color: var(--check-box-border-color);
  }
  mat-label {
    color: #333333;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  div.mat-tab-label-active .mat-tab-label-content {
    color: #2c98f0 !important;
    font-size: 14px;
  }
  .mat-tab-labels {
    background-color: var(--off-background); //#F2F2F2;
    justify-content: space-around !important;
    div {
      width: 50%;
      max-height: 42px;
    }
  }
  .mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    color: var(--tab-text-color);
  }
  .mat-tab-body-content {
    height: 100%;
    overflow: visible;
  }
  .mat-checkbox-inner-container {
    right: unset;
    top: unset;
    position: relative !important;
  }

  mat-tab-group {
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: var(--main-background);
  }

  .mat-tab-label-container {
    background: var(--main-background);
  }
  .mat-tab-header {
    border-bottom: 1px solid var(--tab-border);
  }
}

app-invite-friends {
  // .mat-tab-body-content {
  //     padding: 30px 16px 0px 16px;
  // }
  .mat-expansion-panel-body {
    padding: 0;
  }
  mat-label {
    color: #333333;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  div.mat-tab-label-active .mat-tab-label-content {
    color: #2c98f0 !important;
    font-size: 14px;
  }
  .mat-tab-labels {
    background-color: var(--off-background); //#F2F2F2;
    justify-content: space-around !important;
    div {
      width: 50%;
      max-height: 42px;
    }
  }
  .mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    color: var(--tab-text-color);
  }
  .mat-tab-body-content {
    height: 100%;
    overflow: visible;
  }
  .mat-expansion-panel {
    border-radius: 0px !important;
    box-shadow: unset !important;
  }

  mat-tab-group {
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: var(--main-background);
  }

  .mat-tab-label-container {
    background: var(--main-background);
  }
  .mat-tab-header {
    border-bottom: 1px solid var(--tab-border);
  }
  .mat-expansion-indicator::after {
    color: var(--font-color);
  }
}

app-market-section {
  // .mat-tab-body-content {
  //     padding: 30px 16px 0px 16px;
  // }
  mat-label {
    color: #333333;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  div.mat-tab-label-active .mat-tab-label-content {
    color: #2c98f0 !important;
    font-size: 14px;
  }
  .mat-tab-labels {
    background-color: var(--off-background); //#F2F2F2;
    justify-content: space-around !important;
    div {
      width: 50%;
      max-height: 42px;
    }
  }
  .mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    color: var(--font-color);
  }
  .mat-tab-body-content {
    height: 100%;
    overflow: visible;
  }
  .mat-checkbox-inner-container {
    right: unset;
    top: unset;
    position: relative !important;
  }
  .my-profile {
    .mat-expansion-panel-body {
      background-color: var(--main-background);
      padding: 0 5px 0 5px;
    }
    .mat-expansion-panel {
      border-radius: 0px;
      box-shadow: unset;
    }
    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
      color: var(--font-color);
    }
  }
  .mat-expansion-panel-header {
    padding: 5px;
    border-bottom: 1px solid #bdbdbd;
  }
}

app-signup {
  .mat-tab-body-content {
    padding: 30px 16px 0px 16px;
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #2c98f0;
    height: 2px;
    position: absolute;
    bottom: 0px;
    top: unset;
  }
  .mat-tab-label .mat-tab-label-content :active {
    color: #2c98f0;
  }
  div.mat-tab-label-active .mat-tab-label-content {
    color: #2c98f0 !important;
    font-size: 14px;
  }
  .mat-tab-labels {
    background-color: var(--off-background); //#F2F2F2;
    justify-content: space-around !important;
    div {
      width: 50%;
      max-height: 42px;
    }
  }
  .mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    color: #828282;
  }
  .mat-tab-body-content {
    height: 100%;
    overflow: visible;
  }
  .mat-checkbox-inner-container {
    right: unset;
    top: unset;
    position: relative !important;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--check-box-background-color) !important;
  }

  .mat-checkbox-frame {
    border-color: var(--check-box-border-color);
  }
  mat-label {
    color: var(--font-color);
  }
  input::placeholder {
    color: var(--placeholder-font-color) !important;
  }
}

// globel
.mat-form-field.mat-focused .mat-form-field-ripple {
  background: var(--focused-underline-color);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background: var(--underline-color);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background: var(--underline-color);
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0;
}

// toggle
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2c98f0;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(44, 152, 240, 0.54);
}

button.submitbtn:focus {
  outline: none;
}

.notification-drawer {
  .toolbar {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000;
  }
  .toolbar-heading {
    color: #0a0a0a;
    font-size: 150%;
    padding: 10px;
    text-transform: capitalize;
  }
  #secret-checkbox {
    display: none;
    position: fixed;
  }
  #secret-checkbox:checked ~ .notification-menu {
    margin-top: 0px;
    opacity: 1;
    transform: rotate(0deg) scale(1);
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  .notification-menu {
    position: fixed;
    // left: 35%;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    margin-top: -80vh;
    opacity: 1;
    max-height: 80vh;
    transform: rotate(0deg) scale(1);
    display: flex;
    flex-direction: column;
    transition: all 500ms ease-out;
    box-shadow: 0 0 0 0;
    overflow: auto;
    z-index: 9;
  }
  .notification-item {
    flex: 1;
    margin: 5px;
    margin-left: 0px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    transition: all 300ms ease-in;
  }
  .notification-item:hover {
    box-shadow: inset 3px 0px 0px #333;
  }
  .notification-image {
    flex-basis: 30%;
    // background: url("http://icons.iconarchive.com/icons/sensibleworld/starwars/512/Darth-Vader-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .notification-content {
    padding: 0 10px;
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
  }
  .sender-name {
    flex-basis: 60%;
    display: flex;
    font-weight: bold;
    flex-direction: column;
    justify-content: center;
  }
  .sender-timestamp {
    font-size: 60%;
  }
}

app-bodhitree {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: var(--input-field-bg);
  }
}

app-confirm-order {
  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-color: var(--border-bottom-color);
  }
}

/////////////////////////////////////////////////////////////////////

.mat-input-element {
  caret-color: var(--coret-color);
}

.mat-dialog-container {
  background: var(--popup-color) !important;
  color: var(--tnc-text-color) !important;
}

mat-label {
  color: var(--label-color) !important;
}
input::placeholder {
  color: var(--placeholder-font-color) !important;
}

app-total-charges {
  .mat-card {
    background: var(--main-background);
  }
}

.logodiv,.header-img{
  margin-top: 60px !important;
}
.profile mat-grid-tile:first-child figure{
  justify-content: left !important;
  padding-left: 10px;
}
.profile mat-grid-tile:last-child figure{
  justify-content: right !important;
  padding-right: 10px;
}
.buy-sell-container{
    .mat-grid-tile .mat-figure{
        justify-content: left;
    }
}
app-home{
  sw-item-list {
    width: 100%; float:left;
  }
  sw-item-list:nth-of-type(1) {
      width: calc(50% - 10.5px); float:left;
      padding-right: 10px;
      border-right: 1px solid #333333;
  }
  sw-item-list:nth-of-type(2) {
      width: calc(50% - 10.5px); float:left;
      padding-left: 10px;
  }
}

/*Loader start*/
.overlay {
  // left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222;
  z-index: 999999999999;
  max-width: 500px;
  left: auto;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  // background: #222;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/*Loader end*/